<template>
  <b-card header="Activity" class="mb-4 shadow-sm" no-body>
    <b-overlay :show="loading">
      <b-list-group flush>
        <b-list-group-item v-for="(evt,idx) in activity" :key="idx" class="p-2">
          <icon-text v-if="evt.success" icon="check" icon-variant="success">{{formatActivityDateTime(evt.when)}}, with device {{evt.device}}</icon-text>
          <icon-text v-else icon="times" icon-variant="danger">{{formatActivityDateTime(evt.when)}}, with device {{evt.device}}</icon-text>
        </b-list-group-item>
      </b-list-group>
    </b-overlay>
  </b-card>
</template>

<script>
import IconText from '../../../components/IconText'

export default {
  components: { IconText },
  data: () => ({
    activity: [],
    loading: false
  }),
  methods: {
    formatActivityDateTime (d) {
      const dateObject = new Date(d)
      return `${dateObject.toLocaleDateString('en-GB')} ${dateObject.toLocaleTimeString('en-GB')}`
    },
    async reload () {
      this.loading = true
      try {
        this.activity = await API.self.get_activity()
      } catch (error) {

      } finally {
        this.loading = false
      }
    }
  },
  mounted () {
    this.reload()
  }
}
</script>
