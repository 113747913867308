<template>
  <b-card header="Enabled Services" class="mb-4 shadow-sm" no-body>
    <b-list-group flush>
      <b-list-group-item v-for="(svc, idx) in services" :key="idx" class="d-flex align-items-center">
        <fi icon="check" class="mr-2" />
        <div>{{svc.service_type}}</div>
      </b-list-group-item>
    </b-list-group>
  </b-card>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters({
      services: 'self/services'
    })
  },
  mounted () {
    this.$store.dispatch('self/loadServices')
  }
}
</script>
