<template>
  <b-card header="Change Password" class="mb-4 shadow-sm">
    <b-overlay :show="busy">
      <b-form-group
        label-cols="2"
        label="Old Password"
        label-for="old-pwd"
        label-class="font-weight-bold"
        :state="oldPasswordState"
        :invalid-feedback="oldPasswordFeedback"
      >
        <b-input
          id="old-pwd"
          type="password"
          :state="oldPasswordState"
          v-model="changePassword.old"
        />
      </b-form-group>

      <b-form-group
        label-cols="2"
        label="New Password"
        label-for="new-pwd"
        label-class="font-weight-bold"
        :state="newPasswordState"
        :invalid-feedback="newPasswordFeedback"
      >
        <b-input
          id="new-pwd"
          type="password"
          :state="newPasswordState"
          v-model="changePassword.new"
        />
      </b-form-group>

      <b-form-group
        label-cols="2"
        label="Confirm Password"
        label-for="cnf-pwd"
        label-class="font-weight-bold"
        :state="confirmPasswordState"
        :invalid-feedback="confirmPasswordFeedback"
      >
        <b-input
          id="cnf-pwd"
          type="password"
          :state="confirmPasswordState"
          v-model="changePassword.confirm"
        />
      </b-form-group>

      <b-alert show v-if="result === 'success'" variant="success" class="d-flex align-items-center">
        <fi icon="check" class="mr-2" /><div>Your password has been changed, and is effective immediately.</div>
      </b-alert>
      <b-alert show v-if="result === 'old-password-wrong'" variant="danger" class="d-flex align-items-center">
        <fi icon="times" class="mr-2" /><div>Your old password is incorrect.</div>
      </b-alert>
      <b-alert show v-if="result === 'failure'" variant="danger" class="d-flex align-items-center">
        <fi icon="times" class="mr-2" /><div>An error occurred whilst trying to change your password.  Please contact support.</div>
      </b-alert>

      <b-row>
        <b-col offset="2">
          <b-btn variant="primary" :disabled="!canChange" @click="doChangePassword()">
            <div class="d-flex align-items-center">
              <template v-if="busy">
                <fi icon="spinner" class="mr-2" spin />
                <div>Changing...</div>
              </template>
              <template v-else>
                <fi icon="key" class="mr-2" />
                <div>Change</div>
              </template>
            </div>
          </b-btn>

          <b-btn variant="secondary" class="ml-2" @click="doForgottenPassword()">
            Forgotten Old Password
          </b-btn>

          <b-btn variant="secondary" @click="resetForm" class="ml-2">
            <icon-text>Reset Form</icon-text>
          </b-btn>
        </b-col>
      </b-row>
    </b-overlay>
  </b-card>
</template>

<script>
import IconText from '@/components/IconText'

export default {
  components: { IconText },
  data: () => ({
    busy: false,
    result: null,
    changePassword: {
      old: '',
      new: '',
      confirm: ''
    }
  }),
  computed: {
    oldPasswordState () {
      return (this.changePassword.new.length > 0) || (this.changePassword.old.length > 0) ? this.changePassword.old.length !== 0 : null
    },
    oldPasswordFeedback () {
      return 'You must specify your old password'
    },
    newPasswordState () {
      return this.changePassword.old.length > 0 ? (this.changePassword.new !== this.changePassword.old && this.checkComplexity(this.changePassword.new)) : null
    },
    newPasswordFeedback () {
      if (this.changePassword.new.length === 0) {
        return 'You must enter a new password'
      }

      if (this.changePassword.new === this.changePassword.old) {
        return 'Your new password cannot be the same as your old password'
      }

      if (!this.checkComplexity(this.changePassword.new)) {
        return 'Your new password is not complex enough'
      }

      return ''
    },
    confirmPasswordState () {
      return this.changePassword.old.length > 0 ? (this.changePassword.new.length > 0 && this.changePassword.new === this.changePassword.confirm) : null
    },
    confirmPasswordFeedback () {
      return 'Your new and confirmed passwords do not match'
    },
    changePasswordFormValid () {
      return this.oldPasswordState === true && this.newPasswordState === true && this.confirmPasswordState === true
    },
    canChange () {
      return !this.busy && this.changePasswordFormValid
    }
  },
  methods: {
    checkComplexity (pwd) {
      return pwd.length > 7
    },
    resetForm () {
      this.changePassword = Object.assign({}, this.changePassword, {
        old: '',
        new: '',
        confirm: ''
      })
    },
    async doChangePassword () {
      if (!await this.$store.dispatch('dialog/showConfirm', { title: 'Change Password', message: 'Are you sure you want to change your password?' })) {
        return
      }

      this.busy = true
      this.result = null
      try {
        await this.$store.dispatch('auth/changePassword', this.changePassword)
        this.result = 'success'
        this.resetForm()
      } catch (error) {
        if (error.response.status === 401) {
          this.result = 'old-password-wrong'
        } else {
          this.result = 'failure'
        }
      } finally {
        this.busy = false
      }
    },
    doForgottenPassword () {

    }
  },
  mounted () {
    this.result = null
    this.resetForm()
  }
}
</script>
