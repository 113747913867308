<template>
  <div>
    <b-card header="Account Details" class="mb-4 shadow-sm">
      <b-form-group label-cols="2" label="Name" label-for="name" label-class="font-weight-bold">
        <b-input id="name" readonly :value="user.name" />
      </b-form-group>
      <b-form-group label-cols="2" label="Email Address" label-for="email" label-class="font-weight-bold">
        <b-input id="email" readonly :value="user.email_address" />
      </b-form-group>
      <b-form-group label-cols="2" label="Username" label-for="username" label-class="font-weight-bold">
        <b-input id="username" readonly :value="user.username" />
      </b-form-group>
    </b-card>

    <change-password />

    <services />

    <activity />
  </div>
</template>

<script>
import ChangePassword from './ChangePassword'
import Services from './Services'
import Activity from './Activity'
import { mapGetters } from 'vuex'

export default {
  components: { ChangePassword, Services, Activity },
  computed: {
    ...mapGetters({
      user: 'auth/user'
    })
  }
}
</script>
